<template>
	<v-row no-gutters class="fill-height">
		<v-col cols="12"></v-col>
		<v-col cols="12">
			<v-card width="100vw" max-width="360px" outlined class="mx-auto">
				<v-card-title>{{ tenantName }} {{ appName }}</v-card-title>
				<v-card-subtitle>Wachtwoord vergeten</v-card-subtitle>
				<v-card-text class="py-8 py-lg-16">
					<v-window v-model="step">
						<v-window-item :value="1">
							<p class="text-caption">
								We sturen je een nieuw wachtwoord naar dit
								adres:
							</p>
							<v-text-field
								outlined
								label="Email"
								v-model="mailTo"
							></v-text-field>
						</v-window-item>
						<v-window-item :value="2">
							<v-card-text>
								<p class="red--text text-center my-4">
									{{ errMsg }}
								</p>
							</v-card-text>
						</v-window-item>
						<v-window-item :value="3">
							<v-card-text>
								<p class="text-center my-4">
									We hebben je een nieuw wachtwoord verzonden.
								</p>
							</v-card-text>
						</v-window-item>
					</v-window>
				</v-card-text>
				<v-card-actions>
					<v-btn v-if="step === 2" plain @click.stop="retry">
						Terug
					</v-btn>
					<v-spacer />
					<v-btn
						v-if="step === 1"
						:disabled="!mailTo"
						:loading="loading"
						plain
						color="primary"
						@click.stop="resetPassword"
					>
						Wachtwoord herzetten
					</v-btn>

					<v-btn v-if="step === 3" plain @click.stop="backToLogin">
						Aanmelden
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12"></v-col>
	</v-row>
</template>
<script>
	import { fireAuth, sendPasswordResetEmail } from "@/services/firebase";
	export default {
		name: "ResetPassword",
		data: () => ({
			step: 1,
			mailTo: "",
			error: false,
			errMsg: "",
			loading: false,
			appName: process.env.VUE_APP_APP_NAME,
			tenantName: process.env.VUE_APP_TENANT_NAME,
			appUrl: process.env.VUE_APP_APP_URL,
		}),
		activated() {
			//Called when a kept-alive component is activated.
			this.step = 1;
			this.mailTo = "";
		},
		methods: {
			backToLogin() {
				this.$router.push({ name: "Login" });
			},
			resetPassword() {
				this.loading = true;
				const actionCodeSettings = {
					url: this.appUrl,
					handleCodeInApp: false,
				};
				sendPasswordResetEmail(fireAuth, this.mailTo, actionCodeSettings)
					.then(() => {
						this.error = false;
						this.errMsg = null;
						this.step = 3;
					})
					.catch((error) => {
						console.log(error);
						this.error = true;
						this.errMsg = "Er is iets misgelopen..";
						this.step = 2;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			retry() {
				this.step = 1;
			},
		},
	};
</script>
